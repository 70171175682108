export const serverData = [
  {
    refundRequestDate: new Date('2023-08-19T15:15:00'),
    userEmail: 'haruki.tanaka@example.com',
    patientName: 'Yuki Nakamura',
    orderNumber: 'ORD-5748392',
    amount: 22300,
    paymentMethod: 'Credit card',
    status: 'Refund completed',
    invoiceLink: 'View Invoice',
  },
  {
    refundRequestDate: new Date('2023-08-19T15:15:00'),
    userEmail: 'd.watanabe67@example.com',
    patientName: 'Haruka Yoshida',
    orderNumber: 'ORD-8521947',
    amount: 7500,
    paymentMethod: 'Bank transfer',
    status: 'Refund in progress',
    invoiceLink: 'View Invoice',
  },
  {
    refundRequestDate: new Date('2023-06-03T16:10:00'),
    userEmail: 'haruki.tanaka@example.com',
    patientName: 'Daichi Watanabe',
    orderNumber: 'ORD-5748392',
    amount: 7500,
    paymentMethod: 'Konbini',
    status: 'Refund accepted',
    invoiceLink: 'View Invoice',
  },
  {
    refundRequestDate: new Date('2022-11-09T10:00:00'),
    userEmail: 'haruki.tanaka@example.com',
    patientName: 'Satoshi Nakamura',
    orderNumber: 'ORD-2948756',
    amount: 5600,
    paymentMethod: 'Credit card',
    status: 'Refund completed',
    invoiceLink: 'View Invoice',
  },
];
