import React, { ReactNode } from 'react';
import { theme } from '@imago-cloud/design-system';
import { Stack, SxProps } from '@mui/material';

export default function SplitHalfLayout({ sxProps, children }: { sxProps?: SxProps; children: ReactNode }) {
  return (
    <Stack sx={{ width: '100%', height: '100%', ...sxProps }} direction="row">
      {children}
    </Stack>
  );
}

function Left({ sxProps, children }: { sxProps?: SxProps; children: ReactNode }) {
  return (
    <Stack sx={{ borderRight: `1px solid ${theme.palette.divider}`, width: '100%', height: '100%', ...sxProps }}>
      {children}
    </Stack>
  );
}

function Right({ sxProps, children }: { sxProps?: SxProps; children: ReactNode }) {
  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '584px',
        height: '100%',
        justifyContent: 'space-between',
        overflowY: 'auto',
        ...sxProps,
      }}
    >
      {children}
    </Stack>
  );
}

SplitHalfLayout.Left = Left;
SplitHalfLayout.Right = Right;
