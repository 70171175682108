import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from '@imago-cloud/design-system';

import { ChatIcon } from '../../../../assets';
import { useUnreadChannelMessageCount } from '../../../../shared/hooks/Sendbird/useCreateChannel';
import { ChatInfo } from '../../OrderDetail';
import ViewerChatWindow from './ViewerChatWindow';

interface ChatButtonProps {
  chatInfo: ChatInfo;
  isLoading: boolean;
}
export default function ChatButton({ chatInfo, isLoading }: ChatButtonProps) {
  const { t } = useTranslation();

  const { unreadMessageCount, getUnreadMessageCount } = useUnreadChannelMessageCount(chatInfo?.chattingChannelUrl);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatClick = async () => {
    setIsChatOpen((prev) => !prev);
    await getUnreadMessageCount();
  };

  return (
    <>
      <Badge
        badgeContent={unreadMessageCount}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          position: 'absolute',
          top: '40px',
          right: '20px',
          zIndex: 0,
        }}
      >
        <Button
          onClick={handleChatClick}
          size="60"
          color="grey"
          variant="outlined"
          startIcon={<ChatIcon />}
          loading={isLoading}
        >
          {t('chat.messages')}
        </Button>
      </Badge>
      <ViewerChatWindow
        chatInfo={chatInfo}
        onClose={handleChatClick}
        isOpen={!!chatInfo.chattingChannelUrl && isChatOpen}
      />
    </>
  );
}
