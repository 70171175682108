import { atom } from 'recoil';

export const refundHistoryQueryDefault = {
  startDate: undefined,
  endDate: undefined,
  filteredStatus: ['REFUNDED', 'REFUND_REQUESTED'],
  searchTarget: 'PATIENT_NAME',
  search: '',
};

export const refundHistoryQueryState = atom({
  key: 'refundHistoryQueryState',
  default: refundHistoryQueryDefault,
});
