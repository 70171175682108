import { useEffect, useState } from 'react';
import { IconButton, Ricon, TextField, theme } from '@imago-cloud/design-system';

type SearchFieldProps = {
  value: string; // 초기 값
  onUpdateQuery: (value: string) => void; // 검색어 업데이트 핸들러
};

export const SearchField: React.FC<SearchFieldProps> = ({ value, onUpdateQuery }) => {
  const [searchKeyword, setSearchKeyword] = useState(value);
  useEffect(() => {
    setSearchKeyword('');
  }, [window.location.pathname]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchKeyword(value);
  };

  const handleClear = () => {
    setSearchKeyword('');
    onUpdateQuery('');
  };

  const handleSubmit = () => {
    onUpdateQuery(searchKeyword);
  };

  const isSearchActive = searchKeyword.length > 0;

  return (
    <TextField
      value={searchKeyword}
      onChange={handleInputChange}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleSubmit();
      }}
      placeholder="Search"
      InputProps={{
        startAdornment: isSearchActive && (
          <IconButton size="36" color="grey800" variant="transparent" onClick={handleClear}>
            <Ricon
              icon="ri-arrow-left-line"
              svgProps={{
                fill: theme.palette.grey[800],
              }}
            />
          </IconButton>
        ),
        endAdornment: (
          <IconButton size="36" color="grey800" variant="transparent" onClick={handleSubmit}>
            <Ricon icon="ri-search-line" />
          </IconButton>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: 0,
          marginLeft: isSearchActive ? '4px' : '16px',
          marginRight: '8px',
        },
      }}
    />
  );
};
