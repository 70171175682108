import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Menu, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

import { usePopover } from '../../../shared/hooks';
import { NoticeTooltip } from '../../../shared/ui/NoticeTooltip/NoticeTooltip';

type FilterOption = {
  label: string;
  value: string;
};

type FilterMenuProps = {
  width: string;
  options: FilterOption[];
  label: string;
  filteredStatus: string[];
  onFilterChange: (selected: string[]) => void;
  notice?: string;
};

export const StatusFilter = ({ options, onFilterChange, filteredStatus, notice, label, width }: FilterMenuProps) => {
  const { t } = useTranslation();

  const isAllSelected = filteredStatus?.length === options.length;
  const { open, handleClose, anchorRef, handleAnchorRef } = usePopover();

  const labelColor = isAllSelected ? theme.palette.grey['800'] : theme.palette.primary.main;
  const handleStatusFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorRef) {
      handleClose();
    } else {
      handleAnchorRef(e);
    }
  };

  const handleSelectAllChange = () => {
    const updatedStatusArray = isAllSelected ? [] : options.map((option) => option.value);

    // Immediately update the query with the selected all or cleared status list
    onFilterChange(updatedStatusArray);
  };

  const handleStatusChange = (option: any) => {
    console.log(option);
    const currentStatusArray = filteredStatus || [];
    let updatedStatusArray;

    if (currentStatusArray?.includes(option)) {
      // Remove the option if it's already selected
      updatedStatusArray = currentStatusArray.filter((status) => status !== option);
    } else {
      // Add the option if it's not selected
      updatedStatusArray = [...currentStatusArray, option];
    }

    onFilterChange(updatedStatusArray);
  };

  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Stack sx={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
        <Button
          variant="text"
          color="grey"
          size="36"
          sx={{
            '& .MuiButton-endIcon': { width: 24, height: 24, svg: { width: '24px', height: '24px' } },
          }}
          endIcon={
            <Ricon
              icon={'ri-arrow-down-s-fill'}
              svgProps={{
                fill: labelColor,
                width: 24,
                height: 24,
              }}
            />
          }
          onClick={handleStatusFilterClick}
        >
          <Typography variant={'Subtitle_Semibold14'} color={labelColor}>
            {label}
          </Typography>
        </Button>
        <Menu
          anchorEl={anchorRef}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: { width, p: '8px', pb: '16px', borderRadius: '8px' },
          }}
          sx={{ flexDirection: 'column' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack sx={{ pt: '10px', pl: '10px', mb: '8px' }}>
            <Typography variant={'Subtitle_Semibold16'}>{t('list_header_my_orders.status')}</Typography>
          </Stack>
          <Stack onClick={handleSelectAllChange} sx={styles.menuItem(isAllSelected)}>
            <Checkbox size={'18'} checked={isAllSelected} onChange={() => handleSelectAllChange()} />
            <Typography variant={'Body14'}>{t('All')}</Typography>
          </Stack>

          {options.map(({ label, value }) => (
            <Stack
              key={value}
              onClick={(e) => handleStatusChange(value)}
              sx={styles.menuItem(filteredStatus?.includes(value))}
            >
              <Checkbox size={'18'} checked={filteredStatus?.includes(value)} onChange={() => ({ label, value })} />
              <Typography variant={'Body14'}> {t(label)}</Typography>
            </Stack>
          ))}
        </Menu>
      </Stack>
      {notice && <NoticeTooltip description={notice} iconSize={20} iconColor={labelColor} placement={'top'} />}
    </Stack>
  );
};

const styles = {
  menuItem: (isSelected: boolean) => ({
    height: '34px',
    padding: '8px 16px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    borderRadius: '8px',
    marginBottom: '2px',
    backgroundColor: isSelected ? theme.palette.action.hover : 'inherit',
    '&:hover': { backgroundColor: theme.palette.action.hover },
  }),
};
