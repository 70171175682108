import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { Loading } from '@imago-cloud/design-system';
import { Appearance, Stripe } from '@stripe/stripe-js';

import { PaymentForm } from './PaymentForm';

export const StripeContent = ({
  stripePromise,
  clientSecret,
}: {
  stripePromise: Promise<Stripe | null>;
  clientSecret?: string;
}) => {
  const appearance: Appearance = {
    theme: 'stripe',
  };

  if (!clientSecret) {
    return <Loading type={'basic'} size={'24'} />;
  }
  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <PaymentForm clientSecret={clientSecret} />
    </Elements>
  );
};
