import React, { useEffect } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { Stack, styled } from '@mui/material';
import { ROUTES } from 'constants/routes';
import { usePermission } from 'shared/hooks/usePermission';
import { scrollBar } from 'shared/styles/globalCss';
import { BottomSnackbar, TopAlert } from 'shared/ui';

import { useUserQuery } from '../../../api/userQuery';
import { useAuthState } from '../../../pages/Auth/store/useAuthState';
import { isUserRegistered } from '../../../pages/Auth/useAuthHoc';
import { useGlobalDialog } from '../../../store';
import Header from '../layout/Header';
import SNBDrawer from '../layout/SNBDrawer';
import { CompleteProfileDialogue } from '../organization/CompleteProfileDialogue';

export const OrderListRoutes = [
  `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderList}`,
  `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.receivedOrderList}`,
  `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.receiveOrderDetail}`,
  `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderDetail}`,
];
export const RailSNBRoutes = [...OrderListRoutes, `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.newOrder}`];

export default function GlobalLayout() {
  const { pathname } = useLocation();
  const isRailRoute = RailSNBRoutes.some((path) => matchPath({ path, end: true }, pathname));
  const isOrderRoute = OrderListRoutes.some((path) => matchPath({ path, end: true }, pathname));

  const { data: userData } = useUserQuery();
  const { authInfo } = useAuthState();
  const { isAuthorized, isErrorPage } = authInfo;

  const { addDialog, currentDialogInfo } = useGlobalDialog();
  const isCompleteProfileDialogOpen = currentDialogInfo?.id === 'completeProfileDialog';
  // const { handleError } = useErrorHandler();

  const {
    userRoleConfig: { permissions },
  } = usePermission();

  const isConnectUser = permissions.length !== 0;

  useEffect(() => {
    if (!isCompleteProfileDialogOpen && !isAuthorized && !isUserRegistered(userData)) {
      addDialog({
        id: 'completeProfileDialog',
        type: 'global',
        component: <CompleteProfileDialogue />,
      });
    }
  }, [addDialog, isAuthorized, isCompleteProfileDialogOpen, userData]);

  return (
    <Stack sx={{ position: 'fixed', width: '100%', height: '100%' }}>
      <Header />
      <Wrapper isRailRoute={isRailRoute} isOrderRoute={isOrderRoute}>
        <Stack direction="row" sx={{ minWidth: '1280px', width: '100%', height: '100%' }}>
          <SNBDrawer isRailRoute={isRailRoute} />
          {/*<CustomSendbirdProvider>*/}
          <ContentWrapper isRailRoute={isRailRoute}>{isConnectUser && <Outlet />}</ContentWrapper>
          {/*</CustomSendbirdProvider>*/}
          <TopAlert />
          <BottomSnackbar />
        </Stack>
      </Wrapper>
    </Stack>
  );
}

export const Wrapper = styled('div')<{ isRailRoute: boolean; isOrderRoute?: boolean }>`
  width: 100%;
  height: 100%;
  //height: -webkit-fill-available;
  overflow-x: ${({ isOrderRoute }) => (isOrderRoute ? 'hidden' : 'auto')};
  overflow-y: ${({ isRailRoute, isOrderRoute }) => (isRailRoute || isOrderRoute ? 'hidden' : 'auto')};
  ${() => scrollBar};
`;

export const ContentWrapper = styled('div')<{ isRailRoute: boolean }>`
  width: 100%;
  flex-grow: 1;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    max-width: calc((100vw - (100vw - 100%)) - 80px);
  }
`;
