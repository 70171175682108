import { Column } from '../../PaymentHistory/components/Table/ListHead';
import { RefundStatusFilter } from '../components/RefundStatusFilter';

export const REFUND_ROW_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  p: '19px 75px 19px 40px',
  gap: '20px',
};

export const REFUND_COLUMNS: Column[] = [
  // TODO : key의 경우 server의 type과 매치되도록 수정예정
  {
    key: 'refundRequestDate',
    label: 'Refund request date',
    align: 'left',
    minWidth: '200px',
  },
  {
    key: 'userEmail',
    label: 'User email',
    align: 'left',
    minWidth: '300px',
  },
  {
    key: 'patientName',
    label: 'Patient name',
    align: 'left',
    minWidth: '200px',
  },
  {
    key: 'orderNumber',
    label: 'Order number',
    align: 'left',
    minWidth: '200px',
  },
  {
    key: 'amount',
    label: 'Amount',
    align: 'left',
    minWidth: '150px',
  },
  {
    key: 'paymentMethod',
    label: 'Payment method',
    align: 'left',
    minWidth: '200px',
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    minWidth: '150px',
    render: () => <RefundStatusFilter />,
  },
  {
    key: 'invoiceLink',
    label: '',
    align: 'center',

    minWidth: '150px',
  },
];
