import React, { useEffect } from 'react';
import { Button, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

import { ROUTES } from '../../../constants/routes';
import { postEventMessage } from '../utils/event';

const notifySuccessAndClose = () => {
  if (window.opener) {
    postEventMessage(window.opener, {
      type: 'payment-popup-success',
      payload: {
        route: `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderList}`,
      },
    });
    window.close();
  }
};

export const PaymentSuccess = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', notifySuccessAndClose);

    return () => {
      window.removeEventListener('beforeunload', notifySuccessAndClose);
    };
  }, []);

  return (
    <Stack sx={{ justifyContent: 'center', alignItems: 'center', px: '91px', py: '60px' }}>
      <Stack sx={{ width: '498px' }}>
        <Stack sx={{ gap: '12px', alignItems: 'center' }}>
          <Ricon
            icon={'ri-checkbox-circle-fill'}
            svgProps={{ width: 54, height: 54, fill: theme.palette.success.main }}
          />
          <Stack sx={{ gap: '4px', alignItems: 'center' }}>
            <Typography variant={'H24'}>Payment successful</Typography>
            <Typography variant={'Subtitle_Semibold14'} color={theme.palette.text.thirdary}>
              Your payment has been processed successfully.
              <br />
              You can view your order status in 'My Orders.'
            </Typography>
          </Stack>
        </Stack>
        <Button variant="contained" size="48" sx={{ width: '100%', mt: '28px' }} onClick={notifySuccessAndClose}>
          Go to My orders
        </Button>
      </Stack>
    </Stack>
  );
};
