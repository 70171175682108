import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import React, { ReactNode, useEffect } from 'react';
import { Stack } from '@mui/material';

import { useSendbirdSessionToken } from '../../../api/sendbird';
import { useUserQuery } from '../../../api/userQuery';
import { UserGetResponse } from '../../../client';
import { isUserRegistered } from '../../../pages/Auth/useAuthHoc';
import { RetryChatInfo } from '../../../pages/OrderDetail/OrderDetail';
import { LoadingCenter } from '../../ui/loading/LoadingCenter';
import GroupChannelRetry from './GroupChannelRetry';

const myColorSet = {
  '--sendbird-light-primary-100': '#fff',
  '--sendbird-light-primary-200': '#6BD0FF',
  '--sendbird-light-primary-300': '#00A3EC',
  '--sendbird-light-primary-400': '#0083CA',
  '--sendbird-light-primary-500': '#333333',
};

interface CustomSendbirdProviderProps {
  children: ReactNode;
  retryChatInfo?: RetryChatInfo;
}

function CustomSendbirdProvider({ children, retryChatInfo }: Readonly<CustomSendbirdProviderProps>) {
  const { data: userData } = useUserQuery();
  const isRegistered = isUserRegistered(userData);

  const chatUserInfo = isRegistered ? getChatUserInfo(userData.data) : { organizationId: '' };
  const { data, isLoading } = useSendbirdSessionToken(chatUserInfo.organizationId);

  if (isRegistered === false) {
    return <>{children}</>;
  }
  if (isLoading || data?.data.token === undefined) {
    return (
      <Stack sx={{ width: '100vw', height: '100vh' }} justifyContent="center" alignItems="center">
        <LoadingCenter />
      </Stack>
    );
  }

  return (
    <SendbirdProvider
      appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
      userId={chatUserInfo.organizationId}
      colorSet={myColorSet}
      accessToken={data?.data.token}
    >
      <SendbirdInvitationPreference />
      {/*{retryChatInfo && <GroupChannelRetry retryChatInfo={retryChatInfo} />}*/}
      {children}
    </SendbirdProvider>
  );
}

export default CustomSendbirdProvider;

const getChatUserInfo = (userData: UserGetResponse) => {
  // sendbird Id
  const organizationId = userData.organization?.id as string;
  // Nickname
  const nickname = userData.organization?.name as string;

  return { organizationId, nickname };
};

const SendbirdInvitationPreference = () => {
  const { stores } = useSendbirdStateContext();
  const sdk = stores.sdkStore.sdk;

  useEffect(() => {
    if (!sdk || !sdk.currentUser) return;
    const setInvitationPreference = async () => {
      if (!sdk) return;
      const autoAccept = true;
      await sdk.setChannelInvitationPreference(autoAccept);
    };
    setInvitationPreference();
  }, [sdk]);

  return null;
};
