import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Dayjs } from 'dayjs';

import { useGetPaymentHistory } from '../../api/payment';
import DateRangePicker from '../../shared/ui/DateRangePicker/DateRangePicker';
import { SubHeader } from './components/SubHeader';
import { ListBody } from './components/Table/ListBody';
import { ListHead } from './components/Table/ListHead';
import { PAYMENT_COLUMNS, PAYMENT_ROW_STYLE } from './constants/list';
import { mapServerDataToUI } from './utils/ui';

const PaymentHistory = () => {
  const { t } = useTranslation();
  const onChangeDate = (value: { startDate: Dayjs | null; endDate: Dayjs | null }) => {
    console.log({ startDate: value.startDate?.toISOString(), endDate: value.endDate?.toISOString() });
  };

  const { data } = useGetPaymentHistory({ page: 1, size: 10, sort: ['createdAt,DESC'] });
  const formatData = mapServerDataToUI(data?.data.content ?? [], PAYMENT_COLUMNS);
  return (
    <Stack>
      <SubHeader title={t('Payment history')}>
        <DateRangePicker handleDateChange={onChangeDate} disableBeforeYears={3} />
      </SubHeader>
      <Stack sx={{ minWidth: 'fit-content', overflowX: 'scroll' }}>
        <ListHead columns={PAYMENT_COLUMNS} commonRowStyle={PAYMENT_ROW_STYLE} />
        <ListBody data={formatData} commonRowStyle={PAYMENT_ROW_STYLE} />
      </Stack>
    </Stack>
  );
};
export default PaymentHistory;
