import React from 'react';
import { ErrorPage, Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { Shape } from 'client/types.gen';
import withAsyncBoundary from 'hoc/withAsyncBoundary';
import RadioButtonList from 'pages/NewOrder/components/Prescription/ProductOptionDialog/RadioButtonList';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';

import { useMaterialsByMethods, useMethodsByProsthesis, useShapesByPrevious } from '../../../../../api/productQuery';

const CustomLoading = () => (
  <Stack sx={{ height: '120px' }} justifyContent="center">
    <Loading size="24" type="basic" />
  </Stack>
);

interface MethodOptionListProp extends Record<string, unknown> {
  selectedProsthesis: string;
  selectedValue: string;
  outerOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MethodOptionList = ({ selectedProsthesis, selectedValue, outerOnChange }: MethodOptionListProp) => {
  const {
    orderData: { designManufactureCombination },
  } = useNewOrderDataContext();

  const {
    data: { data: methodList },
  } = useMethodsByProsthesis({
    designManufactureCombination: designManufactureCombination!,
    prosthesis: selectedProsthesis,
  });

  return <RadioButtonList selectedValue={selectedValue} optionList={methodList} outerOnChange={outerOnChange} />;
};

interface MaterialOptionListProp extends Record<string, unknown> {
  selectedProsthesis: string;
  selectedMethod: string;
  selectedValue: string;
  outerOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaterialOptionList = ({
  selectedProsthesis,
  selectedMethod,
  selectedValue,
  outerOnChange,
}: MaterialOptionListProp) => {
  const {
    orderData: { designManufactureCombination },
  } = useNewOrderDataContext();

  const {
    data: { data: materialList },
  } = useMaterialsByMethods({
    designManufactureCombination: designManufactureCombination!,
    prosthesis: selectedProsthesis,
    method: selectedMethod,
  });
  return <RadioButtonList selectedValue={selectedValue} optionList={materialList} outerOnChange={outerOnChange} />;
};

interface ShapeOptionListProp extends Record<string, unknown> {
  selectedProsthesis: string;
  selectedMethod: string;
  selectedMaterial: string;
  selectedValue: string;
  outerOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  innerOnChange: (item: Shape) => void;
}

const ShapeOptionList = ({
  selectedProsthesis,
  selectedMethod,
  selectedMaterial,
  selectedValue,
  outerOnChange,
  innerOnChange,
}: ShapeOptionListProp) => {
  const {
    orderData: { designManufactureCombination },
  } = useNewOrderDataContext();

  const {
    data: { data: shapeList },
  } = useShapesByPrevious({
    designManufactureCombination: designManufactureCombination!,
    prosthesis: selectedProsthesis,
    method: selectedMethod,
    material: selectedMaterial,
  });

  return (
    <RadioButtonList
      selectedValue={selectedValue}
      optionList={shapeList}
      outerOnChange={outerOnChange}
      innerOnChange={innerOnChange}
    />
  );
};

export const AsyncMethodOptionList = withAsyncBoundary<MethodOptionListProp>(MethodOptionList, {
  pendingFallback: <CustomLoading />,
  rejectedFallback: <ErrorPage />,
});

export const AsyncMaterialOptionList = withAsyncBoundary<MaterialOptionListProp>(MaterialOptionList, {
  pendingFallback: <CustomLoading />,
  rejectedFallback: <ErrorPage />,
});

export const AsyncShapeOptionList = withAsyncBoundary<ShapeOptionListProp>(ShapeOptionList, {
  pendingFallback: <CustomLoading />,
  rejectedFallback: <ErrorPage />,
});
