import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, NumberCounter, Typography } from '@imago-cloud/design-system';
import { List, ListItem, Stack } from '@mui/material';
import { OrganizationResponse } from 'client/types.gen';
import { queryClient } from 'shared/providers';
import useTopAlert from 'store/topAlert/useTopAlert';

import { usePatchOrganizationCapacity } from '../../api/organization';
import { useUserQuery } from '../../api/userQuery';

const Operation = () => {
  const { t } = useTranslation();
  const { data: userData, isSuccess } = useUserQuery();
  const organization = userData.data?.organization as OrganizationResponse;

  const { setTopAlert } = useTopAlert();
  const { mutateAsync } = usePatchOrganizationCapacity();

  const [capacity, setCapacity] = useState<number>(organization.orderCapacity);

  const description = t('set_capacity.please_enter_the_maximum').split('\n');

  const handleCapacityUpdate = async () => {
    if (capacity) {
      await mutateAsync(
        { capacity },
        {
          onSuccess: () => {
            setTopAlert({ open: true, description: 'Updated successfully', severity: 'success' });
            queryClient.invalidateQueries({
              queryKey: ['userData'],
            });
          },
        },
      );
    }
  };

  const handleNumberInputBlur = (e: React.FocusEvent<Element, Element>) => {
    const target = e.target as HTMLInputElement;
    setCapacity(Number(target.value));
  };

  return (
    <Stack sx={{ padding: '40px' }}>
      <Stack gap="40px" sx={{ maxWidth: '400px' }}>
        <Stack gap="20px">
          <Stack gap="16px">
            <Typography variant="H18">{t('set_capacity.file_name_prefix')}</Typography>
            <List sx={{ marginLeft: '24px', padding: 0 }}>
              {description.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'list-item',
                    listStyleType: 'disc',
                    p: 0,
                    wordBreak: 'break-word',
                    typography: 'Body14',
                  }}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="Subtitle_Semibold16">{t('set_capacity.subtitle_maximum')}</Typography>
            <NumberCounter
              variant="outlined"
              size="40"
              item={{ min: 0, max: 999999, step: 1, unit: t('set_capacity.number_field_units') }}
              value={Number(capacity)}
              onChange={(value) => setCapacity(value)}
              onBlur={handleNumberInputBlur}
              sx={{ minWidth: '147px' }}
            />
          </Stack>
        </Stack>
        <Stack alignItems="flex-end">
          <Button
            size="48"
            color="primary"
            variant="contained"
            disabled={organization.orderCapacity === capacity || capacity < 1}
            onClick={handleCapacityUpdate}
          >
            {t('set_capacity.btn_update')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Operation;
