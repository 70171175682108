import React, { ReactNode } from 'react';
import { Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const SubHeader = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '100%',
        height: '140px',
        p: '40px 40px 52px 48px',
        justifyContent: 'space-between',
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
        <Typography variant={'H36'}>{title}</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '12px' }}>{children}</Stack>
    </Stack>
  );
};
