import React from 'react';
import { Button, Label, Typography } from '@imago-cloud/design-system';
import dayjs from 'dayjs';

import { formattedPrice } from '../../../shared/utils/formatString';

export const mapServerDataToUI = (
  data: any[],
  columns: Array<{ key: string; minWidth: string; align: 'left' | 'center' | 'right' }>,
) => {
  return data.map((item) => {
    const row: Record<string, any> = {};

    columns.forEach((column) => {
      row[column.key] = {
        value: formatValueByKey(column.key, item[column.key as keyof typeof item]),
        minWidth: column.minWidth,
        align: column.align,
      };
    });

    return row;
  });
};

export const formatValueByKey = (key: string, value: any) => {
  console.log(key);
  switch (key) {
    case 'refundRequestDate':
      return dayjs(value).format('YYYY-MM-DD HH:mm'); // 날짜를 지정된 형식으로 포맷팅
    case 'amount':
      return <Typography sx={{ color: 'red' }}>{formattedPrice(value)}</Typography>; // 금액 포맷팅 및 스타일 추가
    case 'status':
      return <Label>{value}</Label>;
    case 'invoiceLink':
      return (
        <Button variant={'text'} size={'36'}>
          View Invoice
        </Button>
      );
    default:
      return value;
  }
};
