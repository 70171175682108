import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { designManufactureCombination } from 'client/types.gen';
import { priceMeasuredOrderDataState } from 'pages/NewOrder/store/atoms';
import { useRecoilState } from 'recoil';
import { ScrollArea } from 'shared/styles/scrollArea';
import { getFormatDate } from 'shared/utils/formatDate';

export const PatientInfo = () => {
  const { t } = useTranslation();

  const [priceMeasuredOrderData, setPriceMeasuredOrderData] = useRecoilState(priceMeasuredOrderDataState);
  const { patient, designConfirmProcessIncluded, designManufactureCombination, note } = priceMeasuredOrderData || {};

  const [isNoteCollapsed, setIsNoteCollapsed] = useState(false);
  const handleNoteCollapseClick = () => setIsNoteCollapsed((prev) => !prev);

  return (
    <ScrollArea sx={{ width: '100%', overflow: 'hidden auto', maxHeight: 'calc(100vh - 191px)' }}>
      <Stack direction="column" gap="4px">
        <Stack direction="column" gap="2px">
          <Typography variant="H18">{patient?.name}</Typography>
          <Typography variant="Subtitle_Semibold16" color="text.secondary">
            {`${patient?.birth ? getFormatDate(patient.birth) : '-'},${patient?.age ? patient.age : '-'},${t(`dialog_gender.${patient?.gender?.toLowerCase()}`)}`}
          </Typography>
        </Stack>

        <Stack direction="column" gap="2px">
          <PatientInfoRow
            label={t('order_overview.order_range')}
            value={t(convertOrderRangeText(designManufactureCombination!))}
          />
          <PatientInfoRow
            label={t('order_overview.design_confirm_process')}
            value={t(`patient_page_info.${designConfirmProcessIncluded ? 'include' : 'exclude'}`)}
          />
          <PatientInfoRow
            label={t('order_overview.visit_date')}
            value={patient?.visitDate ? getFormatDate(patient?.visitDate) : '-'}
          />
          <PatientInfoRow
            label={t('order_overview.try-in_date')}
            value={patient?.tryInDate ? getFormatDate(patient?.tryInDate) : '-'}
          />
        </Stack>
      </Stack>
      {/*note 표시*/}
      {note && (
        <Stack
          direction="column"
          gap="8px"
          sx={{
            padding: '10px 12px 16px 12px',
            mt: '12px',
            mb: '8px',
            backgroundColor: 'grey.100',
            borderRadius: '8px',
          }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap="4px" sx={{ height: '26px' }}>
            <IconButton size="24" variant="transparent" color="grey800" onClick={handleNoteCollapseClick}>
              <Ricon
                icon={isNoteCollapsed ? 'ri-arrow-right-s-fill' : 'ri-arrow-down-s-fill'}
                svgProps={{ width: 24, height: 24, fill: theme.palette.grey[800] }}
              />
            </IconButton>
            <Typography variant="Subtitle_Semibold14">{t('order_overview.note')}</Typography>
          </Stack>
          {!isNoteCollapsed && (
            <Typography variant="Body14" color="text.secondary" sx={{ ml: '28px' }}>
              {note}
            </Typography>
          )}
        </Stack>
      )}
    </ScrollArea>
  );
};

const PatientInfoRow = ({ label, value }: { label: string | React.ReactNode; value: string | React.ReactNode }) => {
  return (
    <Stack direction="row" textAlign="center">
      <Typography variant="Subtitle_Semibold14" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="Body14" color="text.secondary">
        &nbsp;{value}
      </Typography>
    </Stack>
  );
};

const convertOrderRangeText = (orderRange: designManufactureCombination) => {
  switch (orderRange) {
    case 'MANUFACTURE':
      return 'dialog_order_type.manufacture_only';
    case 'DESIGN':
      return 'dialog_order_type.design_only';
    case 'DESIGN_AND_MANUFACTURE':
      return 'dialog_order_type.design_&_manufacture';
    default:
      return '';
  }
};
