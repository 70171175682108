import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@imago-cloud/design-system';

import { ROUTES } from '../../../constants/routes';

export const PaymentForm = ({ clientSecret }: { clientSecret: string }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_CONNECT_CLIENT_URL}${ROUTES.PAYMENT_ROUTER.root}/${ROUTES.PAYMENT_ROUTER.success}`,
        receipt_email: email,
      },
    });

    if (error) {
      console.error('Payment failed:', error.message);

      navigate(`${ROUTES.PAYMENT_ROUTER.fail}`, {
        state: {
          errorMessage: error.message,
          paymentIntentId: error.payment_intent?.id,
        },
      });
    }
    setIsLoading(false);
  };

  if (!elements) {
    return;
  }
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        isLoading={isLoading}
        id="submit"
        type="submit"
        variant="contained"
        size="48"
        sx={{ width: '100%', mt: '28px' }}
      >
        Pay now
      </Button>
      {/*{message && <Typography>{message}</Typography>}*/}
    </form>
  );
};
