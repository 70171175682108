import { useState } from 'react';

import { createPaymentIntent } from '../../../api/payment';
import { Order, UserGetResponse } from '../../../client';

export const usePayment = ({
  priceMeasuredOrderData,
  user,
  isDelegateAgreementAccepted,
}: {
  priceMeasuredOrderData?: Order;
  user?: UserGetResponse;
  isDelegateAgreementAccepted: boolean;
}) => {
  const [clientSecret, setClientSecret] = useState<string>('');

  const createPaymentIntentHandler = async () => {
    if (!priceMeasuredOrderData || !user) {
      return;
    }

    try {
      const response = await createPaymentIntent({
        orderId: priceMeasuredOrderData.id,
        amount: priceMeasuredOrderData.billingAmount,
        currency: 'jpy',
        isDelegateAgreementAccepted,
      });

      // swagger type 가져오기
      const result: any = await response.json();
      if (result?.data?.clientSecret) {
        console.log('payment intent result', result, result.data.clientSecret);
        setClientSecret(result.data.clientSecret);
      } else {
        console.error('Client secret is missing in the response:', result);
      }
    } catch {}
  };

  // TODO : 나중에 capture이나 intent status 조회하는 로직도 추가되면 될 듯

  return { clientSecret, createPaymentIntentHandler };
};
