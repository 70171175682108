import { atom } from 'recoil';

type AuthState = {
  isAuthorized: boolean;
  isAuthLoading: boolean;
  isErrorPage: boolean;
};

export const DEFAULT_AUTH_STATE: AuthState = {
  isAuthorized: false,
  isAuthLoading: true,
  isErrorPage: false,
};

export const authState = atom<AuthState>({
  key: 'authState',
  default: DEFAULT_AUTH_STATE,
});
