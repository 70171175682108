import { useRecoilState } from 'recoil';

import { refundHistoryQueryDefault, refundHistoryQueryState } from './atom';

export const useRefundHistoryState = () => {
  const [refundQueryParams, setRefundQueryParams] = useRecoilState(refundHistoryQueryState);

  const updateRefundQueryParams = (newParams: Partial<typeof refundQueryParams>) => {
    setRefundQueryParams((prev) => ({ ...prev, ...newParams }));
  };
  const resetRefundQueryParams = () => setRefundQueryParams(refundHistoryQueryDefault);

  return { refundQueryParams, updateRefundQueryParams, resetRefundQueryParams };
};
