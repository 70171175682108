import React from 'react';
import { Button } from '@imago-cloud/design-system';
import dayjs from 'dayjs';

import { formattedPrice } from '../../../shared/utils/formatString';

export const mapServerDataToUI = (
  data: any[],
  columns: Array<{ key: string; minWidth: string; align: 'left' | 'center' | 'right'; width?: string; color?: string }>,
) => {
  return data.map((item) => {
    const row: Record<string, any> = {};
    columns.forEach((column) => {
      row[column.key] = {
        value: formatValueByKey(column.key, item[column.key as keyof typeof item]),
        minWidth: column.minWidth,
        align: column.align,
        ...(column.width && { width: column.width }),
        ...(column.color && { color: column.color }),
      };
    });

    return row;
  });
};

export const formatValueByKey = (key: string, value: any) => {
  console.log(key);
  switch (key) {
    case 'orderDate':
      return dayjs(value).format('YYYY-MM-DD HH:mm A'); // 날짜를 지정된 형식으로 포맷팅
    case 'paymentAmount':
      return formattedPrice(value);
    case 'invoiceLink':
      return (
        <Button variant={'text'} size={'36'}>
          View Invoice
        </Button>
      );
    default:
      return value;
  }
};
