import { useEffect } from 'react';

import { useUserQuery } from '../../api/userQuery';
import { ImagoErrorResponse, ResponseDtoUserGetResponse, UserGetResponse } from '../../client';
import { usePermission } from '../../shared/hooks/usePermission';
import { useAuthState } from './store/useAuthState';
export const useAuthHoc = () => {
  const { authInfo, setIsAuthLoading, setIsAuthorized } = useAuthState();
  const { isAuthorized, isAuthLoading, isErrorPage } = authInfo;
  const { data } = useUserQuery();
  const userData = data as ImagoErrorResponse | ResponseDtoUserGetResponse;
  const userInfo = data.data;
  const { setValidatedUserRoleConfig } = usePermission();

  const checkPermission = (userInfo: UserGetResponse) => {
    const connectRole = userInfo.roles.find((role) => role !== 'ci_medical:user');
    setValidatedUserRoleConfig({ role: connectRole, permissions: userInfo.permissions });
    setIsAuthorized(true);
  };

  useEffect(() => {
    const initialize = () => {
      const isRegistered = isUserRegistered(userData);
      if (isRegistered) {
        redirectBoUser(userInfo);
        checkPermission(userInfo);
      }
      setIsAuthLoading(false);
    };
    initialize();
  }, []);

  return { isAuthLoading, isAuthorized, isErrorPage };
};

export const isUserRegistered = (userData: ImagoErrorResponse | ResponseDtoUserGetResponse) => {
  return userData.success || !('errorCode' in userData && userData.errorCode === 'CONNECT:USER_NOT_FOUND');
};

const redirectBoUser = (userInfo: UserGetResponse) => {
  const hasBackofficeQcRole = userInfo.roles.includes('ci_medical:connect:backoffice:qc');
  const redirectUrl = hasBackofficeQcRole ? process.env.REACT_APP_CONNECT_BO_CLIENT_URL : null;
  if (redirectUrl) {
    return window.location.replace(redirectUrl as string);
  }
};
