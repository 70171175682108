import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, IconButton, Ricon, theme } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { designManufactureCombination, FileInfo, PatientRequest } from 'client/types.gen';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { FormProvider } from 'shared/ui';
import { getISOStringFromDate } from 'shared/utils/formatDate';

import { useUploadFile } from '../../../../api/fileQuery';
import { useUserQuery } from '../../../../api/userQuery';
import { useGlobalDialog } from '../../../../store';
import { IMPORT_FILE_LIMITATIONS } from '../../constants/dialog';
import { PATIENT_INFO_FORM } from '../../constants/importData';
import { BinaryFileObject, patientDefaultValues } from '../../context/NewOrderDataProvider';
import { validateFileLimits } from '../../utils/validateFileLimit';
import BackStepTitle from '../BackStepTitle';
import SplitHalfLayout from '../SplitHalfLayout';
import DndUpload from './DndUpload';
import PatientInfoForm from './PatientInfoForm';
import Thumbnail from './Thumbnail';

type PatientInfoAndFilePropType = {
  onNext: () => void;
  onPrevious: () => void;
  isRetainForm: boolean;
};

export default function ImportData({ onNext, onPrevious, isRetainForm }: Readonly<PatientInfoAndFilePropType>) {
  const { t } = useTranslation();
  const { addDialog } = useGlobalDialog();

  const { setOrderData, orderData } = useNewOrderDataContext();
  const { patient, binaryFiles, designManufactureCombination } = orderData;

  const { mutateAsync, isPending } = useUploadFile();

  const { data: userData } = useUserQuery();

  const methods = useForm<PatientRequest & { designManufactureCombination: designManufactureCombination }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { ...patient, designManufactureCombination },
  });

  const currentFiles = orderData.binaryFiles.map(({ file }) => file);

  const {
    watch,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  useEffect(() => {
    if (!isRetainForm) reset({ ...orderData.patient, designManufactureCombination });
  }, [isRetainForm]);

  const saveBinaryFilesOnLocal = (newFiles: File[] | null) => {
    if (!newFiles) return;

    const { isFileCountLimit, isFileSizeLimit } = validateFileLimits(currentFiles, newFiles);

    if (isFileCountLimit || isFileSizeLimit) {
      addDialog(IMPORT_FILE_LIMITATIONS);
      return;
    }

    setOrderData((prev) => {
      const newBinaryFileObject = newFiles.map((newFile) => ({ file: newFile, thumbnail: undefined }));
      return {
        ...prev,
        binaryFiles: [...prev.binaryFiles, ...newBinaryFileObject],
      };
    });
  };

  const handleFileUpload = async (fileObj: BinaryFileObject) => {
    const { data, success } = await mutateAsync(fileObj);

    if (!data?.id) return;
    setOrderData((prev) => {
      const updatedBinaryFiles = prev.binaryFiles.map((binFileObj) => {
        if (binFileObj.file.name === fileObj.file.name) {
          const updatedFileObj = { ...binFileObj };
          // @ts-ignore
          updatedFileObj.file.fileId = data.id; // ㅠㅠㅜ
          // @ts-ignore
          updatedFileObj.file.createdAt = data.createdAt;
          return updatedFileObj;
        } else {
          return binFileObj;
        }
      });
      const uploadedFileInfo = { fileId: data.id, createdAt: data.createdAt } as FileInfo;
      return {
        ...prev,
        patientFiles: [...prev.patientFiles, uploadedFileInfo],
        binaryFiles: updatedBinaryFiles,
      };
    });
    return success;
  };

  const uploadAllFiles = (files: Array<BinaryFileObject>) => {
    // @ts-ignore
    const notUploadedFile = files.filter((file) => file.file.fileId === undefined);

    if (notUploadedFile.length === 0) {
      return Promise.all([true]);
    } else {
      const uploadPromises = notUploadedFile.map((fileObj) => handleFileUpload(fileObj));
      return Promise.all(uploadPromises);
    }
  };

  const onSubmit = async ({
    designManufactureCombination,
    ...patient
  }: PatientRequest & { designManufactureCombination: designManufactureCombination }) => {
    const formattedPatient = {
      ...patient,
      birth: patient.birth ? getISOStringFromDate(new Date(patient?.birth)) : undefined,
      tryInDate: patient.tryInDate ? getISOStringFromDate(new Date(patient.tryInDate)) : undefined,
      visitDate: patient.visitDate ? getISOStringFromDate(new Date(patient.visitDate)) : undefined,
    };
    setOrderData((prev) => ({
      ...prev,
      patient: formattedPatient,
      ordererName: {
        firstName: userData?.data?.name?.firstName ?? '',
        lastName: userData?.data?.name?.lastName ?? '',
      },
      designManufactureCombination,
    }));

    const result = await uploadAllFiles(binaryFiles);

    if (result.every((res: any) => res)) {
      setOrderData((prev) => {
        const patientPrintingFiles = prev.binaryFiles
          .filter((fileObj) => prev.printingFilesDisplay.includes(fileObj.file.name))
          // @ts-ignore
          .map((fileObj) => ({ fileId: fileObj.file.fileId, createdAt: fileObj.file.createdAt }));

        return {
          ...prev,
          patientPrintingFiles,
        };
      });
      onNext();
    }
  };

  const isButtonDisabled = binaryFiles.length === 0 || !watch('name');
  const isImportDataDirty = patientDefaultValues !== patient || isDirty;

  return (
    <SplitHalfLayout>
      <SplitHalfLayout.Left sxProps={{ position: 'relative' }}>
        {/* Top */}
        <BackStepTitle
          stepName={t('import_data_field.title_import_data')}
          order={'1/3'}
          onPrevious={onPrevious}
          isDirty={isImportDataDirty}
        />
        {/* Bottom */}
        {!binaryFiles.length ? (
          // File DnD
          <DndUpload currentFiles={currentFiles} saveFiles={saveBinaryFilesOnLocal} />
        ) : (
          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="row"
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              flexWrap: 'wrap',
              padding: '136px 50px 0 72px',
              gap: '28px 48px',
              alignContent: 'flex-start',
              overflow: 'auto',
            }}
          >
            <Box sx={{ position: 'absolute', top: '20px', right: '20px', zIndex: 10 }}>
              <input
                hidden
                type="file"
                id="file-add-input"
                multiple
                accept="*/*"
                onChange={(e) => {
                  if (e.target.files) {
                    const newFiles = Array.from(e.target.files);
                    saveBinaryFilesOnLocal(newFiles);
                  }
                }}
              />
              <IconButton
                size="36"
                icon_size="small"
                hover_color="black"
                variant="outlined"
                // @ts-ignore
                component="label"
                htmlFor="file-add-input"
              >
                <Ricon
                  icon="ri-add-line"
                  svgProps={{
                    width: 18,
                    height: 18,
                    fill: theme.palette.grey[800],
                  }}
                />
              </IconButton>
            </Box>

            {binaryFiles.map(({ file }) => (
              <Thumbnail
                key={file.name}
                file={file}
                cancelFile={(fileName: string) => {
                  setOrderData((prev) => {
                    const updated = prev.binaryFiles.filter((fileObj) => fileObj.file.name !== fileName);
                    return { ...prev, binaryFiles: updated };
                  });
                }}
                storeThumbnail={(thumbnail: Blob) => {
                  setOrderData((prev) => {
                    const updated = prev.binaryFiles.map((fileObj) => {
                      if (file.name === fileObj.file.name) {
                        return { ...fileObj, thumbnail: thumbnail };
                      }
                      return fileObj;
                    });
                    return { ...prev, binaryFiles: updated };
                  });
                }}
              />
            ))}
          </Stack>
        )}
      </SplitHalfLayout.Left>

      <SplitHalfLayout.Right>
        {/* Form */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} id={PATIENT_INFO_FORM.formName}>
          <PatientInfoForm />
        </FormProvider>

        {/*  Button */}
        <Box>
          <Divider />
          <Box sx={{ padding: '8px 16px 16px' }}>
            <Button
              form={PATIENT_INFO_FORM.formName}
              type="submit"
              size="60"
              disabled={isButtonDisabled}
              sx={{ width: '100%' }}
              loading={isPending}
            >
              {t('patient_information.btn_next')}
            </Button>
          </Box>
        </Box>
      </SplitHalfLayout.Right>
    </SplitHalfLayout>
  );
}
