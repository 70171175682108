import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Dayjs } from 'dayjs';

import { useGetRefundHistory } from '../../api/payment';
import DateRangePicker from '../../shared/ui/DateRangePicker/DateRangePicker';
import { SearchField } from '../MyOrderList/components/filter/SearchField';
import { SearchTarget } from '../MyOrderList/components/filter/SearchTarget';
import { OrderSearchTargetType } from '../MyOrderList/constants/menuOptions';
import { SubHeader } from '../PaymentHistory/components/SubHeader';
import { ListBody } from '../PaymentHistory/components/Table/ListBody';
import { ListHead } from '../PaymentHistory/components/Table/ListHead';
import { useRefundHistoryState } from './atom/useRefundHistoryState';
import { REFUND_COLUMNS, REFUND_ROW_STYLE } from './constants/list';
import { SEARCH_MENU_ITEMS } from './constants/menu';
import { serverData } from './constants/mock';
import { mapServerDataToUI } from './utils/ui';

const RefundHistory = () => {
  const { t } = useTranslation();

  const { refundQueryParams } = useRefundHistoryState();
  const { data } = useGetRefundHistory({ page: 1, size: 10, sort: ['createdAt,DESC'] });

  console.log(data);

  const onChangeDate = (value: { startDate: Dayjs | null; endDate: Dayjs | null }) => {
    console.log({ startDate: value.startDate?.toISOString(), endDate: value.endDate?.toISOString() });
  };

  const handleSearchTargetChange = (event: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = event.target.value as OrderSearchTargetType;
    console.log({ searchFilterType: selectedValue });
  };

  const onChangeSearch = (value: string) => console.log({ searchKeyword: value });

  const formatData = mapServerDataToUI(serverData, REFUND_COLUMNS);
  return (
    <Stack>
      <SubHeader title={t('Refund history')}>
        <DateRangePicker handleDateChange={onChangeDate} disableBeforeYears={3} />
        <SearchTarget
          value={refundQueryParams.searchTarget || SEARCH_MENU_ITEMS[0].value}
          onChange={handleSearchTargetChange}
          options={SEARCH_MENU_ITEMS}
        />
        <SearchField value={refundQueryParams.search} onUpdateQuery={onChangeSearch} />
      </SubHeader>
      <Stack sx={{ minWidth: 'fit-content', overflowX: 'scroll' }}>
        <ListHead columns={REFUND_COLUMNS} commonRowStyle={REFUND_ROW_STYLE} />
        <ListBody data={formatData} commonRowStyle={REFUND_ROW_STYLE} />
      </Stack>
    </Stack>
  );
};

export default RefundHistory;
