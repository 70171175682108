import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, IconButton, Menu, MenuItem, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useOrderState } from 'pages/MyOrderList/atoms/useOrderState';
import { DATE_TYPE_OPTIONS, STATUS_FILTER_OPTIONS } from 'pages/MyOrderList/constants/menuOptions';
import { usePopover } from 'shared/hooks';
import { ListHeaderColumnDetailType, ListHeaderDetailType } from 'shared/types/list';
import { NoticeTooltip } from 'shared/ui/NoticeTooltip/NoticeTooltip';

import { searchFilterDefault } from '../../../pages/MyOrderList/atoms/atom';
import { useStatusFilter } from '../../hooks/useStatusFilter';
import { getCurrentOrderStatus } from '../../utils/getOrderStatusConfig';

export const ListHead = ({ headDetail, gap }: { headDetail: ListHeaderDetailType; gap?: string }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        borderBottom: `1px solid ${theme.palette.grey['400']}`,
        p: '12px',
        pr: '27px',
        width: '100%',
        minWidth: 'fit-content',
        alignItems: 'center',
      }}
    >
      <DefaultHeaderContent headDetail={headDetail} gap={gap} />
    </Stack>
  );
};

const DefaultHeaderContent = ({ headDetail, gap }: { headDetail: ListHeaderDetailType; gap?: string }) => {
  return (
    <Stack
      sx={{
        gap,
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        pl: '52px',
        pr: '88px',
      }}
    >
      {Object.entries(headDetail).map(([key, value], index) => {
        if (!value) return null;
        const { type, minWidth, widthPercent, ...columnInfo } = value;

        return (
          <Stack
            key={key}
            sx={{
              minWidth: minWidth,
              flex: widthPercent,
            }}
          >
            {type === 'text' && <ListHead.Text columnInfo={columnInfo} />}
            {type === 'sort' && <ListHead.Sort columnInfo={columnInfo} />}
            {type === 'filter' && <ListHead.Filter columnInfo={columnInfo} />}
          </Stack>
        );
      })}
    </Stack>
  );
};
export const HeaderText = ({ columnInfo }: { columnInfo: Omit<ListHeaderColumnDetailType, 'type' | 'minWidth'> }) => {
  const { label } = columnInfo;
  const { t } = useTranslation();
  return (
    <Typography variant={'Subtitle_Semibold14'} color={theme.palette.grey['800']}>
      {t(label)}
    </Typography>
  );
};

const SortFilter = ({ columnInfo }: { columnInfo: Omit<ListHeaderColumnDetailType, 'type' | 'minWidth'> }) => {
  const { open, handleClose, id, anchorRef, handleAnchorRef } = usePopover();
  const { orderQueryParams, updateOrderQueryParams } = useOrderState();
  const { t } = useTranslation();

  const handleSortTypeButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorRef) {
      handleClose();
    } else {
      handleAnchorRef(e);
    }
  };

  const handleSortTypeChange = (option: { label: string; value: string }) => {
    updateOrderQueryParams({ sort: [option.value, 'DESC'] });
    handleClose();
  };
  return (
    <Stack sx={{ flexDirection: 'row' }}>
      <Button
        variant="text"
        color="grey"
        size="36"
        sx={{
          '& .MuiButton-endIcon': { width: 24, height: 24, svg: { width: '24px', height: '24px' } },
        }}
        endIcon={
          <Ricon
            icon={'ri-arrow-down-s-fill'}
            svgProps={{
              fill: theme.palette.grey['800'],
            }}
          />
        }
        onClick={handleSortTypeButtonClick}
      >
        <Typography variant={'Subtitle_Semibold14'} color={theme.palette.grey['800']}>
          {t(DATE_TYPE_OPTIONS.find(({ value }) => value === orderQueryParams.sort?.[0])?.label || '')}
        </Typography>
      </Button>
      <IconButton
        size={'36'}
        color={'grey800'}
        icon_size={'small'}
        hover_color={'black'}
        variant={'transparent'}
        onClick={() => {
          updateOrderQueryParams({
            sort: [orderQueryParams.sort?.[0] ?? 'DESC', orderQueryParams.sort?.[1] === 'DESC' ? 'ASC' : 'DESC'],
          });
        }}
      >
        <Ricon icon={orderQueryParams.sort?.[1] === 'DESC' ? 'ri-arrow-down-line' : 'ri-arrow-up-line'} />
      </IconButton>
      <Menu
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        // slotProps={{
        //   paper: {
        //     sx: {
        //       maxWidth: '200px',
        //       p: '8px !important',
        //       backgroundColor: 'beige',
        //     },
        //   },
        // }}
        PaperProps={{ sx: { width: '160px', p: '8px', borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {DATE_TYPE_OPTIONS.map(({ label, value }) => (
          <MenuItem
            key={value}
            onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => handleSortTypeChange({ label, value })}
            selected={orderQueryParams.sort?.[0] === value}
          >
            {t(label)}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

const StatusFilter = ({ columnInfo }: { columnInfo: Omit<ListHeaderColumnDetailType, 'type' | 'minWidth'> }) => {
  const { t } = useTranslation();
  const { label, notice } = columnInfo;
  const { updateOrderQueryParams } = useOrderState();
  const currentOrderStatus = getCurrentOrderStatus();

  useEffect(() => {
    updateOrderQueryParams({ filteredStatus: searchFilterDefault.filteredStatus });
  }, [currentOrderStatus]);

  const { open, handleClose, anchorRef, handleAnchorRef } = usePopover();

  const { filteredStatus, isAllSelected, handleStatusChange, handleSelectAllChange } = useStatusFilter();

  const labelColor = isAllSelected ? theme.palette.grey['800'] : theme.palette.primary.main;

  const handleStatusFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorRef) {
      handleClose();
    } else {
      handleAnchorRef(e);
    }
  };

  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Stack sx={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
        <Button
          variant="text"
          color="grey"
          size="36"
          sx={{
            '& .MuiButton-endIcon': { width: 24, height: 24, svg: { width: '24px', height: '24px' } },
          }}
          endIcon={
            <Ricon
              icon={'ri-arrow-down-s-fill'}
              svgProps={{
                fill: labelColor,
                width: 24,
                height: 24,
              }}
            />
          }
          onClick={handleStatusFilterClick}
        >
          <Typography variant={'Subtitle_Semibold14'} color={labelColor}>
            {t(label)}
          </Typography>
        </Button>
        <Menu
          anchorEl={anchorRef}
          open={open}
          onClose={handleClose}
          // slotProps={{
          //   paper: {
          //     sx: {
          //       maxWidth: '200px',
          //       p: '8px !important',
          //       backgroundColor: 'beige',
          //     },
          //   },
          // }}
          PaperProps={{
            sx: { width: '247px', p: '8px', pb: '20px', borderRadius: '8px' },
          }}
          sx={{ flexDirection: 'column' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack sx={{ pt: '10px', pl: '10px', mb: '8px' }}>
            <Typography variant={'Subtitle_Semibold16'}>{t('list_header_my_orders.status')}</Typography>
          </Stack>
          <Stack onClick={handleSelectAllChange} sx={styles.menuItem(isAllSelected)}>
            <Checkbox size={'18'} checked={isAllSelected} onChange={() => handleSelectAllChange()} />
            <Typography variant={'Body14'}>{t('dialog_status.all')}</Typography>
          </Stack>

          {STATUS_FILTER_OPTIONS.map(({ label, value }) => (
            <Stack
              key={value}
              onClick={(e) => handleStatusChange(value)}
              sx={styles.menuItem(filteredStatus?.includes(value) ?? false)}
            >
              <Checkbox size={'18'} checked={filteredStatus?.includes(value)} onChange={() => ({ label, value })} />
              <Typography variant={'Body14'}> {t(label)}</Typography>
            </Stack>
          ))}
        </Menu>
      </Stack>
      {notice && <NoticeTooltip description={notice} iconSize={20} iconColor={labelColor} placement={'top'} />}
    </Stack>
  );
};

ListHead.Text = HeaderText;
ListHead.Sort = SortFilter;
ListHead.Filter = StatusFilter;

const styles = {
  menuItem: (isSelected: boolean) => ({
    height: '34px',
    padding: '8px 16px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    borderRadius: '8px',
    marginBottom: '2px',
    backgroundColor: isSelected ? theme.palette.action.hover : 'inherit',
    '&:hover': { backgroundColor: theme.palette.action.hover },
  }),
};
