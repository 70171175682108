import React, { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from '@imago-cloud/design-system';
import { alpha, Box, Stack } from '@mui/material';
import { OrderResponse, PartnerLabOrderResponse } from 'client/types.gen';
import { useIntersectObserver } from 'shared/hooks/useIntersectObserver';
import { LoadingCenter } from 'shared/ui/loading/LoadingCenter';
import { getCurrentOrderStatus, getOrderStatusConfig } from 'shared/utils/getOrderStatusConfig';

import { useOrderInfiniteQuery } from '../../../api/orderQuery';
import { ROUTES } from '../../../constants/routes';
import { useOrderState } from '../../../pages/MyOrderList/atoms/useOrderState';
import { NoListData } from '../commonList/NoListData';

const ListItemContent = ({
  renderCustomContent,
  order,
  dateType,
}: {
  order: OrderResponse | PartnerLabOrderResponse;
  renderCustomContent: (order: OrderResponse | PartnerLabOrderResponse, dateType: string) => ReactNode;
  dateType: string;
}) => {
  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          position: 'relative',
          width: '100%',
          height: '68px',
          borderRadius: '8px',
          alignItems: 'center',
          gap: '60px',
          cursor: 'pointer',
          pl: '52px',
          pr: '88px',
        }}
      >
        {renderCustomContent(order, dateType)}
      </Stack>
    </>
  );
};

const ListItemWrapper = ({
  order,
  children,
}: {
  order: OrderResponse | PartnerLabOrderResponse;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const { highlightStatuses, getOrderDetailNavigatePath } = getOrderStatusConfig();

  const { orderId, status } = order;
  const isHighlighted = highlightStatuses.includes(status);

  const handleOrderDetailClick = () => navigate(getOrderDetailNavigatePath(orderId));

  return (
    <Stack
      key={orderId}
      sx={{
        width: '100%',
        color: isHighlighted ? theme.palette.primary.main : 'inherit',
        position: 'relative',
        backgroundColor: isHighlighted ? alpha(theme.palette.primary.main, 0.08) : 'white',
        flexDirection: 'row',
        '&:hover': { backgroundColor: theme.palette.grey['100'] },
        borderRadius: '8px',
      }}
      onClick={handleOrderDetailClick}
    >
      {isHighlighted && (
        <Box
          sx={{
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.light,
            position: 'absolute',
            top: '10px',
            left: '10px',
          }}
        />
      )}
      {children}
    </Stack>
  );
};

export const ListBodyWrapper = ({
  renderCustomContent,
}: {
  renderCustomContent: (order: OrderResponse | PartnerLabOrderResponse, dateType: string) => ReactNode;
}) => {
  const navigate = useNavigate();
  const { orderQueryParams, isFiltered } = useOrderState();

  const { data, isLoading, hasNextPage, isFetching, fetchNextPage } = useOrderInfiniteQuery(orderQueryParams);
  const flattenedData = useMemo(
    () => data?.pages.flatMap((page) => (page?.data ? page.data.content : [])) ?? [],
    [data],
  );

  const orderList: (OrderResponse | PartnerLabOrderResponse)[] = useMemo(
    () => flattenedData.filter((item): item is OrderResponse => item !== undefined),
    [flattenedData],
  );

  const loadMoreRef = useIntersectObserver(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      await fetchNextPage();
    }
  });

  const currentOrderStatus = getCurrentOrderStatus();
  const { getOrderDataDescription } = getOrderStatusConfig();
  const { title, subtitle } = getOrderDataDescription(isFiltered);

  const redirectToNewOrder = () => navigate(`${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.newOrder}`);

  return (
    <Stack
      sx={{
        p: '12px',
        minWidth: 'fit-content',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      {isLoading ? (
        <LoadingCenter />
      ) : !orderList.length ? (
        <NoListData
          isFiltered={isFiltered}
          title={title}
          subtitle={subtitle}
          {...(currentOrderStatus === 'orderer' && {
            button: { text: 'page_my_orders_empty.btn_start_new_order', onClick: redirectToNewOrder },
          })}
        />
      ) : (
        <>
          {orderList?.map((order) => (
            <ListItemWrapper key={order.orderId} order={order}>
              <ListItemContent
                order={order}
                renderCustomContent={renderCustomContent}
                dateType={orderQueryParams.sort?.[0] ?? 'statusUpdatedAt'}
              />
            </ListItemWrapper>
          ))}
          <div ref={loadMoreRef} />
        </>
      )}
    </Stack>
  );
};
