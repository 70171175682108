import React from 'react';
import { theme, Typography } from '@imago-cloud/design-system';
import { SxProps, TableCell, TableHead, TableRow } from '@mui/material';

export type Column = {
  key: string;
  label: string;
  align: 'center' | 'right' | 'left';
  width?: string;
  minWidth: string;
  color?: string;
  render?: () => React.ReactNode;
};

interface ListHeadProps {
  commonRowStyle?: SxProps;
  columns: Column[];
}
export const ListHead: React.FC<ListHeadProps> = ({ columns, commonRowStyle }) => (
  <TableHead>
    <TableRow
      sx={{
        ...commonRowStyle,
        borderBottom: `solid 1px ${theme.palette.divider}`, //공통속성 x
      }}
    >
      {columns.map((col, index) => (
        <TableCell
          key={index}
          align={col.align || 'left'}
          sx={{ p: 0, border: 'none', width: col.width || '100%', minWidth: col.minWidth, alignSelf: 'center' }}
        >
          {col.render ? (
            col.render()
          ) : (
            <Typography variant={'Subtitle_Semibold14'} color={theme.palette.grey['800']}>
              {col.label}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
