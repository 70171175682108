import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConnectAuthProvider } from '@imago-cloud/auth-client';
import { usePermission } from 'shared/hooks/usePermission';
import VtkProvider from 'shared/hooks/useVTKContext';
import { ImagoThemeProvider, queryClient } from 'shared/providers';
import { createAppRouter } from 'shared/routes/router';
import GlobalStyles from 'shared/styles/GlobalStyles';
import { GlobalDialog } from 'shared/ui/globalDialog';

import ErrorBoundary from './hoc/ErrorBoundary';
import { I18nHoc } from './hoc/I18nHoc';
import AuthHoc, { FallbackLoading, KyServiceInitialization } from './pages/Auth/AuthHoc';
import CustomSendbirdProvider from './shared/hooks/Sendbird/CustomSendbirdProvider';
import GlobalErrorDialog from './shared/ui/globalDialog/error/GlobalErrorDialog';

function App() {
  const { role } = usePermission();

  // focusManager.setEventListener((handleFocus) => {
  //   if (typeof window !== 'undefined' && window.addEventListener) {
  //     const focusHandler = () => {
  //       // Trigger handleFocus when the window gains focus
  //       handleFocus(true);
  //     };
  //
  //     const blurHandler = () => {
  //       // Optionally handle when the window loses focus
  //       handleFocus(false);
  //     };
  //
  //     window.addEventListener('focus', focusHandler);
  //     window.addEventListener('blur', blurHandler);
  //
  //     // Clean up event listeners when a new handler is set or component unmounts
  //     return () => {
  //       window.removeEventListener('focus', focusHandler);
  //       window.removeEventListener('blur', blurHandler);
  //     };
  //   }
  // });

  return (
    <QueryClientProvider client={queryClient}>
      <ImagoThemeProvider>
        <GlobalStyles />
        <ErrorBoundary>
          <ConnectAuthProvider>
            <KyServiceInitialization />
            <Suspense fallback={<FallbackLoading />}>
              <AuthHoc>
                <CustomSendbirdProvider>
                  <VtkProvider>
                    <I18nHoc>
                      <GlobalErrorDialog />
                      <RouterProvider router={createAppRouter(role)} />
                    </I18nHoc>
                  </VtkProvider>
                </CustomSendbirdProvider>
              </AuthHoc>
            </Suspense>
          </ConnectAuthProvider>
        </ErrorBoundary>
        <GlobalDialog />
      </ImagoThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
