import React from 'react';
import { theme, Typography } from '@imago-cloud/design-system';
import { SxProps, TableBody, TableCell, TableRow } from '@mui/material';

export const ListBody = ({ data, commonRowStyle }: { data?: Record<string, any>[]; commonRowStyle?: SxProps }) => {
  return (
    <TableBody>
      {data?.map((row, index) => <Row key={index} rowData={row} commonRowStyle={commonRowStyle} />)}
    </TableBody>
  );
};

interface RowProps {
  rowData: Record<string, any>;
  commonRowStyle?: SxProps;
}

const Row: React.FC<RowProps> = ({ rowData, commonRowStyle }) => (
  <TableRow
    sx={{
      ...commonRowStyle,

      borderBottom: `1px solid ${theme.palette.grey['100']}`, //공통속성 x
    }}
  >
    {Object.entries(rowData).map(([key, col], index) => (
      <TableCell
        key={index}
        align={col.align || 'left'}
        sx={{ p: 0, width: col.width || '100%', minWidth: col.minWidth, border: 'none', alignSelf: 'center' }}
      >
        {typeof col.value === 'string' ? (
          <Typography variant={'Body18'} color={col.color || theme.palette.text.primary}>
            {col.value}
          </Typography>
        ) : (
          col.value
        )}
      </TableCell>
    ))}
  </TableRow>
);
