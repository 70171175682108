import React, { useLayoutEffect, useState } from 'react';
import { useResponseErrorHandler, useResponseSuccessHandler } from '@imago-cloud/auth-client';
import { Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

import { kyServiceInstance } from '../../api/kyInstance';
import { ErrorPage } from '../Error/ErrorPage';
import { useAuthHoc } from './useAuthHoc';

const AuthHoc = ({ children }: { children: React.ReactNode }) => {
  const { isErrorPage, isAuthLoading } = useAuthHoc();

  if (isAuthLoading) {
    return <FallbackLoading />;
  }
  if (isErrorPage) {
    return <ErrorPage />;
  }
  return <>{children}</>;
};
export default AuthHoc;

export const FallbackLoading = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100vw', height: '100vh', zIndex: 999999999 }}>
      <Loading size="44" type="basic" />
    </Stack>
  );
};

export const KyServiceInitialization = () => {
  // for trigger re-render
  const [_, setKyServiceInitialisation] = useState<typeof kyServiceInstance | null>(null);

  const { handleResponseSuccess } = useResponseSuccessHandler();
  const { handleResponseError } = useResponseErrorHandler();

  useLayoutEffect(() => {
    kyServiceInstance.onResponseSuccess = handleResponseSuccess;
    kyServiceInstance.onResponseError = handleResponseError;
    if (kyServiceInstance.onResponseError && kyServiceInstance.onResponseSuccess) {
      setKyServiceInitialisation(kyServiceInstance);
      console.log('ky initialized.');
    }
  }, [handleResponseError, handleResponseSuccess]);

  return null;
};
