import React from 'react';

import { useRefundHistoryState } from '../atom/useRefundHistoryState';
import { StatusFilter } from './StatusFilter';

export const RefundStatusFilter = () => {
  const { refundQueryParams, updateRefundQueryParams } = useRefundHistoryState();
  const { filteredStatus } = refundQueryParams;
  const REFUND_STATUS_MENU = [
    { label: 'Refund requested', value: 'REFUND_REQUESTED' },
    { label: 'Refund completed', value: 'REFUNDED' },
  ];

  const updateStatusFilter = (newStatus: string[]) => {
    updateRefundQueryParams({ filteredStatus: newStatus });
  };
  return (
    <StatusFilter
      width={'215px'}
      filteredStatus={filteredStatus}
      onFilterChange={updateStatusFilter}
      options={REFUND_STATUS_MENU}
      label={'Status'}
    />
  );
};
