import { NavigateFunction } from 'react-router-dom';

import { PaymentPayloadType } from '../../Payment/PaymentPopup';
import { PopupEventMessage } from '../../Payment/types/event';
import { postEventMessage } from '../../Payment/utils/event';

export const openPaymentPopup = (navigate: NavigateFunction, popupPayloadData: PaymentPayloadType) => {
  const popupWindow = window.open('/payment', 'StripePayment', 'width=680,height=690,scrollbars=yes,resizable=yes');

  //팝업이 차단된 경우
  if (!popupWindow) {
    alert('The popup was blocked. Please allow popups in your browser settings.');
    return;
  }

  const onPopupMessageReceived = (event: MessageEvent<PopupEventMessage>) => {
    if (event.origin !== window.location.origin || event.source !== popupWindow) {
      console.warn('Invalid message source or origin.');
      return;
    }

    const { type, payload: receivedData } = event.data;

    switch (type) {
      case 'payment-popup-ready':
        // 팝업이 준비되면 데이터를 전송
        postEventMessage(popupWindow, {
          type: 'initialize-data',
          payload: popupPayloadData,
        });
        break;

      case 'payment-popup-success':
        // 결제 완료 후
        if (receivedData?.route) {
          navigate(receivedData.route);
          popupWindow.close();
        }
        break;
    }
  };

  window.addEventListener('message', onPopupMessageReceived);
};
