import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cicon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const NoListData = ({
  isFiltered,
  title,
  subtitle,
  button,
}: {
  isFiltered: boolean;
  title: string;
  subtitle: string;
  button?: { text: string; onClick: () => void };
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isFiltered && (
        <Stack sx={{ mb: '12px' }}>
          <Cicon icon={'NoResult'} />
        </Stack>
      )}
      <Stack sx={{ color: theme.palette.text.secondary, gap: '8px', alignItems: 'center' }}>
        <Typography variant={'H24'}>{t(title)}</Typography>
        <Typography variant={'Subtitle_Semibold16'}>{t(subtitle)}</Typography>
      </Stack>
      {!isFiltered && button && (
        <Button size={'48'} variant={'outlined'} color={'grey'} onClick={button.onClick} sx={{ mt: '32px' }}>
          {t(button.text)}
        </Button>
      )}
    </Stack>
  );
};
