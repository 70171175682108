import { ListHeaderDetailType } from 'shared/types/list';

export const RECEIVED_ORDER_LIST_HEADER_CONFIG: ListHeaderDetailType = {
  patientName: {
    type: 'text',
    label: 'list_header.patient_name',
    minWidth: '224px',
    widthPercent: '30%',
  },
  organizationName: {
    type: 'text',
    label: 'list_header.organization_name',
    minWidth: '242px',
    widthPercent: '30%',
  },
  orderItems: {
    type: 'text',
    label: 'list_header_my_orders.info',
    minWidth: '330px',
    widthPercent: '20%',
  },
  status: {
    type: 'filter',
    label: 'list_header_my_orders.status',
    minWidth: '180px',
    notice: 'status_tooltip.body',
    widthPercent: '15%',
  },
  isRemake: {
    type: 'text',
    label: 'list_header_my_orders.remake',
    minWidth: '90px',
    widthPercent: '15%',
  },
  statusUpdatedAt: {
    type: 'sort',
    label: 'list_header_my_orders.modified',
    minWidth: '220px',
    widthPercent: '15%',
  },
};

export const NO_RECEIVED_ORDER_DESCRIPTION = {
  title: 'no_received_orders_yet.title',
  subtitle: 'no_received_orders_yet.subtitle',
};
