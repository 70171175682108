import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Divider, TextField, theme, Typography } from '@imago-cloud/design-system';
import { alpha, Stack } from '@mui/material';
import { ShippingInformation } from 'pages/NewOrder/components/Submit/ShippingInformation';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { priceMeasuredOrderDataState } from 'pages/NewOrder/store/atoms';
import { useRecoilState } from 'recoil';
import { useDialogue } from 'shared/hooks/useDialogue';
import { ScrollArea } from 'shared/styles/scrollArea';

import { useAddChattingUrlOnOrder, useUpdateShippingMutation } from '../../../../api/orderQuery';
import { useUserQuery } from '../../../../api/userQuery';
import { useCreateChannel } from '../../../../shared/hooks/Sendbird/useCreateChannel';
import { formattedPrice } from '../../../../shared/utils/formatString';
import { ORDER_SUBMIT_FAILED, ORDER_SUBMIT_SUCCESSFULLY } from '../../constants/orderSubmitDialogue';
import { openPaymentPopup } from '../../utils/openPaymentPopup';
import { OrderSubmitDialogue } from './SubmitDialogue/OrderSubmitDialogue';

export const SubmitOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [isDelegateAgreementAccepted, setIsDelegateAgreementAccepted] = useState(true);
  const [priceMeasuredOrderData, setPriceMeasuredOrderData] = useRecoilState(priceMeasuredOrderDataState);
  const isDesignOnlyOrder = priceMeasuredOrderData?.designManufactureCombination === 'DESIGN';

  const orderSubmitSuccessfully = useDialogue();
  const orderSubmitFailed = useDialogue();

  const { shippingData, setShippingData, orderData } = useNewOrderDataContext();
  const { mutateAsync: createChannelAsync, isPending: isChannelCreating } = useCreateChannel();

  const { data: userData } = useUserQuery();
  const user = userData?.data;
  const { mutateAsync: updateShippingAsync, isPending: isOrderSubmitting } = useUpdateShippingMutation();
  const { mutateAsync: addChatUrlAsync } = useAddChattingUrlOnOrder();

  const handleSubmitOrder = async () => {
    try {
      // 2. 그룹 채널 생성 with orderId
      const channel = await createChannelAsync({
        orderId: shippingData.orderId as string,
        ordererId: user.organization?.id as string, // 유저 -> Org. 로
        patientName: orderData.patient.name,
      });
      if (!channel) {
        throw new Error('Chatting not created.');
      }
      // 3. path api 로 url 보내기
      await addChatUrlAsync({ orderId: shippingData.orderId as string, chattingChannelUrl: channel.url });
    } catch (e) {
      console.error('Sendbird channel creation failed: ', e);
    }
  };

  const updateShippingInfo = async () => {
    try {
      await updateShippingAsync(shippingData);
    } catch (e) {
      console.error('Failed to update shipping info:', e);
    }
  };

  useEffect(() => {
    if (userData && priceMeasuredOrderData) {
      setShippingData((prev) => ({
        ...prev,
        orderId: priceMeasuredOrderData.id,
        receiver: {
          name: {
            ...(user?.name ?? prev.receiver.name),
          },
          number: user?.organization?.businessPhone.number ?? prev.receiver.number,
        },
        shippingAddress: {
          ...(user?.organization?.address ?? prev.shippingAddress),
        },
      }));
    }
  }, [userData, priceMeasuredOrderData]);

  if (!priceMeasuredOrderData) {
    return null;
  }

  const handlePaymentButtonClick = async () => {
    await updateShippingInfo();
    await openPaymentPopup(navigate, { user, priceMeasuredOrderData, isDelegateAgreementAccepted });
  };

  const { orderAmount, printingFilesAmount, shippingFee, billingAmount } = priceMeasuredOrderData;

  return (
    <>
      <ScrollArea sx={{ width: '100%', height: '100vh', overflow: 'hidden auto', marginBottom: '92px' }}>
        <Stack direction="column" sx={{ padding: '40px', overflow: 'auto', width: '100%', height: '100%' }} gap="30px">
          {!isDesignOnlyOrder && (
            <Stack sx={{ gap: '30px' }}>
              {/*shipping information 표시*/}
              <ShippingInformation shippingData={shippingData} />
              {/*delivery instruction 표시*/}
              <Stack direction="column" gap="12px">
                <SubmitOrderInfoTitle title={t('submit_panel.delivery_instruction')} />
                <TextField
                  label={t('submit_panel.delivery_instruction') as string}
                  label_visible="none"
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setShippingData((prev) => ({ ...prev, deliveryInstruction: e.target.value }));
                  }}
                />
              </Stack>
            </Stack>
          )}
          {/*payment due 표시*/}
          <Stack direction="column" gap="12px">
            <SubmitOrderInfoTitle title={t('submit_panel.payment_due')} />
            <Stack
              direction="column"
              gap="4px"
              sx={{
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              }}
            >
              <Stack direction="column" gap="8px">
                <PaymentDueTextRow label={t('submit_panel.subtotal')} value={orderAmount + printingFilesAmount} />
                <PaymentDueTextRow label={t('submit_panel.shipping_fee')} value={shippingFee} />
              </Stack>
              <Divider />
              <PaymentDueTextRow
                label={t('submit_panel.total')}
                value={billingAmount}
                variant={'H16'}
                color={'primary.main'}
              />
            </Stack>
          </Stack>
          <Checkbox
            size={'18'}
            label={t('shipping_info.check_box')}
            sx={{ mr: '12px' }}
            checked={isDelegateAgreementAccepted}
            onChange={(e) => setIsDelegateAgreementAccepted(e.target.checked)}
          />
        </Stack>
      </ScrollArea>
      {/*proceed with payment 표시 */}
      <Stack
        sx={{
          padding: '16px 28px',
          minHeight: '92px',
          borderTop: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: 2,
        }}
      >
        <Button
          size="60"
          variant="contained"
          disabled={!shippingData || !priceMeasuredOrderData || !isDelegateAgreementAccepted}
          sx={{ width: '100%' }}
          loading={isOrderSubmitting || isChannelCreating}
          onClick={handlePaymentButtonClick}
        >
          {t('submit_panel.btn_proceed_with_payment')}
        </Button>
      </Stack>
      {/*order submit Dialogue*/}
      {orderSubmitSuccessfully.open && (
        <OrderSubmitDialogue
          open={orderSubmitSuccessfully.open}
          onClose={orderSubmitSuccessfully.handleCloseDialogue}
          dialogueInfo={ORDER_SUBMIT_SUCCESSFULLY}
        />
      )}
      {orderSubmitFailed.open && (
        <OrderSubmitDialogue
          open={orderSubmitFailed.open}
          onClose={orderSubmitFailed.handleCloseDialogue}
          dialogueInfo={ORDER_SUBMIT_FAILED}
        />
      )}
    </>
  );
};

export const SubmitOrderInfoTitle = ({ title }: { title: string }) => (
  <Typography variant="Subtitle_Semibold16" color="text.secondary">
    {title}
  </Typography>
);

const PaymentDueTextRow = ({
  label,
  value,
  variant = 'Body16',
  color = 'text.primary',
}: {
  label: string;
  value: number;
  variant?: React.ComponentProps<typeof Typography>['variant'];
  color?: React.ComponentProps<typeof Typography>['color'];
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant={variant} color={color}>
        {label}
      </Typography>
      <Typography variant={variant} color={color}>
        {formattedPrice(value, t)}
      </Typography>
    </Stack>
  );
};
