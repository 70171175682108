import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const PaymentFail = () => {
  const location = useLocation();
  const { errorMessage, paymentIntentId } = location.state || {};

  useEffect(() => {
    console.log(errorMessage, paymentIntentId);
  }, [errorMessage, paymentIntentId]);

  const handleConfirm = () => {
    window.close();
  };

  return (
    <Stack sx={{ justifyContent: 'center', alignItems: 'center', px: '91px', py: '60px' }}>
      <Stack sx={{ width: '498px' }}>
        <Stack sx={{ gap: '12px', alignItems: 'center' }}>
          <Ricon icon={'ri-close-circle-fill'} svgProps={{ width: 54, height: 54, fill: theme.palette.error.main }} />
          <Stack sx={{ gap: '4px', alignItems: 'center' }}>
            <Typography variant={'H24'}>Payment failed</Typography>
            <Typography variant={'Subtitle_Semibold14'} color={theme.palette.text.thirdary}>
              {errorMessage || 'Unknown error'}
            </Typography>
          </Stack>
        </Stack>
        <Button variant="contained" size="48" sx={{ width: '100%', mt: '28px' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
