import ky, { KyInstance } from 'ky';

import { ImagoErrorResponse } from '../client';

const kyInstanceConfig = {
  prefixUrl: process.env.REACT_APP_MAIN_SERVER_URL as string,
  credentials: 'include' as RequestCredentials,
  timeout: 180_000,
  retry: 3,
};

class KyService {
  private accessToken: string = '';
  kyInstance: KyInstance;
  onResponseSuccess: Function | null = null;
  onResponseError: Function | null = null;

  constructor() {
    this.kyInstance = this.createKyInstance(process.env.REACT_APP_MAIN_SERVER_URL);
  }

  private createKyInstance(prefixUrl: string | undefined): KyInstance {
    return ky.create({
      ...kyInstanceConfig,
      prefixUrl: prefixUrl,
      hooks: {
        beforeRequest: [
          async (request) => {
            request.headers.set('Authorization', this.accessToken);
          },
        ],
        afterResponse: [
          async (request, options, response) => {
            if (response.status === 400) {
              // /me 에서 useSuspenseQuery 를 사용했기 때문에 명시적으로 Response 를 만들어서 넘길필요.
              // suspenseQuery 는 항상 성공을 보장하기 때문에.
              const { success, message, errorCode } = (await response.json()) as ImagoErrorResponse;
              if (errorCode === 'CONNECT:USER_NOT_FOUND') {
                return new Response(JSON.stringify({ success, errorCode, message, status: response.status })); //
              }
              // 임시
              else if (errorCode === 'ACCOUNT:INVALID_TOKEN') {
                if (this.onResponseError) {
                  const customError = { response, config: { _retry: false } };
                  const accessToken = await this.onResponseError(customError);
                  this.accessToken = `Bearer ${accessToken.accessToken}`;
                  request.headers.set('Authorization', this.accessToken);
                  return this.kyInstance(request);
                }
                // local | dev
                // window.location.replace(process.env.REACT_APP_ACCOUNTS_CLIENT_URL as string);
              }
            } else if (response.status === 401) {
              // AccessToken Refresh
              if (this.onResponseError) {
                const customError = { response, config: { _retry: false } };

                try {
                  const accessToken = await this.onResponseError(customError);
                  this.accessToken = `Bearer ${accessToken.accessToken}`;
                  request.headers.set('Authorization', this.accessToken);
                  return this.kyInstance(request);
                } catch (rejectedError) {
                  // 강제 로그아웃 시 account 이동
                  const { data } = (
                    rejectedError as {
                      response: { data: { errorCode: string; statusCode: number } };
                    }
                  ).response;
                  const { errorCode, statusCode } = data;
                  if (statusCode === 401 && errorCode === 'ACCOUNT:UNAUTHORIZED') {
                    // Logout redirect malfunction in auth-client
                    window.location.replace(process.env.REACT_APP_ACCOUNTS_CLIENT_URL as string);
                  }
                  throw rejectedError;
                }
              }
              // User not registered
            } else if (response.status === 403) {
              const { errorCode, ...rest } = (await response.json()) as ImagoErrorResponse;
              if (errorCode === 'CONNECT:USER_TENANT_NOT_ALLOWED') {
                window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
              }
            }
          },
        ],
      },
    });
  }

  public getKyInstance(): KyInstance {
    return this.kyInstance;
  }
}

export const kyServiceInstance = new KyService();
export const kyInstance = kyServiceInstance.getKyInstance();
