import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  CreatePaymentIntentRequest,
  GetPaymentHistoryData,
  GetRefundHistoryData,
  ResponseDtoPagePaymentHistoryResponse,
  ResponseDtoPageRefundHistoryResponse,
} from '../client';
import { PAYMENT_HISTORY, REFUND_HISTORY, STRIPE_PAYMENT_INTENT } from '../shared/constants/apiUrl';
import { kyInstance } from './kyInstance';

export const createPaymentIntent = (paymentInfo: CreatePaymentIntentRequest) => {
  return kyInstance.post(STRIPE_PAYMENT_INTENT, {
    json: paymentInfo,
  });
};

export const useGetPaymentHistory = (
  data: GetPaymentHistoryData,
): UseQueryResult<ResponseDtoPagePaymentHistoryResponse> => {
  return useQuery({
    queryKey: ['paymentHistory', data],
    queryFn: () =>
      kyInstance
        .get(PAYMENT_HISTORY, {
          searchParams: {
            page: data.page as number,
            size: data.size as number,
          },
        })
        .json(),
  });
};

export const useGetRefundHistory = (
  data: GetRefundHistoryData,
): UseQueryResult<ResponseDtoPageRefundHistoryResponse> => {
  return useQuery({
    queryKey: ['refundHistory', data],
    queryFn: () =>
      kyInstance
        .get(REFUND_HISTORY, {
          searchParams: {
            page: data.page as number,
            size: data.size as number,
          },
        })
        .json(),
  });
};
