import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';

import { Order, UserGetResponse } from '../../client';
import { usePayment } from '../NewOrder/hooks/usePayment';
import { StripeContent } from './components/StripeContent';
import { PopupEventMessage } from './types/event';
import { postEventMessage } from './utils/event';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

export type PaymentPayloadType = {
  user: UserGetResponse;
  priceMeasuredOrderData: Order;
  isDelegateAgreementAccepted: boolean;
};
export const PaymentPopup = () => {
  const [paymentPayload, setPaymentPayloadData] = useState<PaymentPayloadType>();

  const { user, priceMeasuredOrderData, isDelegateAgreementAccepted } = paymentPayload || {};
  const { clientSecret, createPaymentIntentHandler } = usePayment({
    priceMeasuredOrderData,
    user,
    isDelegateAgreementAccepted: isDelegateAgreementAccepted ?? true,
  });

  const onParentMessageReceived = useCallback((event: MessageEvent<PopupEventMessage>) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    const { type, payload } = event.data;
    if (!type) return;
    const { user, priceMeasuredOrderData, isDelegateAgreementAccepted } = payload;
    switch (type) {
      case 'initialize-data': {
        setPaymentPayloadData({ user, priceMeasuredOrderData, isDelegateAgreementAccepted });
        break;
      }
    }
  }, []);

  // 부모 창과 메시지 초기화
  useEffect(() => {
    if (!window.opener) {
      window.location.href = '/';
      return;
    }
    postEventMessage(window.opener, { type: 'payment-popup-ready' });
    window.addEventListener('message', onParentMessageReceived);

    return () => {
      window.removeEventListener('message', onParentMessageReceived);
    };
  }, [onParentMessageReceived]);

  useEffect(() => {
    const initializePaymentIntent = async () => {
      try {
        await createPaymentIntentHandler();
      } catch (error) {
        console.error('Payment intent creation failed:', error);
      }
    };

    if (!clientSecret && paymentPayload) {
      initializePaymentIntent();
    } else {
      console.log('clientSecret 존재! ');
    }
  }, [clientSecret, paymentPayload]);

  return (
    <Stack sx={{ px: '91px', py: '32px', gap: '32px' }}>
      <Typography variant={'H24'}>Enter your credit card information</Typography>
      <StripeContent stripePromise={stripePromise} clientSecret={clientSecret} />
    </Stack>
  );
};
